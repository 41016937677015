
import { defineComponent } from 'vue';

export default defineComponent({
    emits: ['close', 'import'],
    setup() {
        function download() {
            window.open('/download/template/Resident-Template.xlsx');
        }

        return {
            download
        };
    }
});
