import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cus_button = _resolveComponent("cus-button")
  const _component_import_face_dialog = _resolveComponent("import-face-dialog")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_cus_button, {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowImportDialog=true))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextImportFace), 1)
      ]),
      _: 1
    }),
    (_ctx.isShowImportDialog)
      ? (_openBlock(), _createBlock(_component_import_face_dialog, {
          key: 0,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShowImportDialog=false))
        }))
      : _createCommentVNode("", true)
  ]))
}