
import { defineComponent, Ref, ref } from 'vue';
import { community } from '@/data';
import HttpRequest from '@/util/axios.config';
import importFaceTipUtil from '@/components/view/pm/import-face-tip/importFaceTipUtil';
import ImportResidentDialog from './components/import-resident-dialog.vue';

export default defineComponent({
    components: {
        ImportResidentDialog
    },
    setup() {
        const fileRef = ref();
        const setFile = () => {
            fileRef.value.click();
        };
        // 6.5.3 点击展示导入rfcard弹窗选择
        const isShowImportResidentDialog = ref(false);

        const {
            pushImportProjectTaskUUIDs,
            clearGetResidentTaskListInterval,
            getImportResidentDetail
        } = importFaceTipUtil();

        const importResident = () => {
            isShowImportResidentDialog.value = false;
            const formData = new FormData();
            formData.append('Resident', fileRef.value.files[0]);
            HttpRequest.post('v3/web/community/resident/import', formData, [(res: { data: { ImportProjectTaskUUID: string } }) => {
                // 添加新任务ID到查询ids中
                pushImportProjectTaskUUIDs(res.data.ImportProjectTaskUUID);
                // 清空定时器
                clearGetResidentTaskListInterval();
                getImportResidentDetail();
            }, false]);
            fileRef.value.value = '';
        };
        return {
            fileRef,
            setFile,
            importResident,
            projectType: community.ProjectType,
            isShowImportResidentDialog
        };
    }
});
