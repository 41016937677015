
import {
    defineComponent, watch, ref,
    Ref
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import addButton from '@/components/common/add-button/index.vue';
import chooseBuildRoomInit from '@/methods/building-apt-choose';
import {
    listPagination, RequestData, ListHeaderItem,
    filterEmptyCellValue, ListActionItem, SearchKey
} from '@/components/common/list';
import faceImport from '@/components/view/pm/import-face';
import rfCardImport from '@/components/view/pm/import-rfcard';
import payApp from '@/components/view/common/subscription/module/pay/pay-app.vue';
import remove from '@/methods/remove-func';
import { community } from '@/data';
import HttpRequest from '@/util/axios.config';
import router, { propertyBaseRouter, propertySubRouter } from '@/router';
import { ChangeWordList } from '@/methods/setText';
import { limitErrorImg } from '@/methods/basicFun';
import Notice from '@/util/notice.base';
import FaceImportPlus from '@/components/view/pm/import-face-plus/index.vue';
import { updateSortField, getSortImg, getInitSortField } from '@/methods/sort-table';
import eventBus from '@/util/use-event-bus/global-event-bus';
import ImportResident from './import-resident/index.vue';
import ImportDialog from './components/import-dialog.vue';
import { getProjectData } from './util';

const breadHeaders = ref([{ label: ChangeWordList.ResidentOrPeople }]);

export default defineComponent({
    components: {
        propertyBreadCrumb,
        addButton,
        faceImport,
        listPagination,
        payApp,
        rfCardImport,
        FaceImportPlus,
        ImportResident,
        ImportDialog
    },
    setup() {
        const imgItem = ref<Array<any>>([]);
        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };

        // 办公区分标志
        const projectType = community.ProjectType;
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const searchKeyList: Ref<SearchKey> = ref([]);
        const requestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const deleteUrl = ref('');
        watch(projectType, () => {
            const projectData = getProjectData()[projectType.value];
            headers.value = projectData.header;
            searchKeyList.value = projectData.searchKeyList;
            requestData.value = projectData.requestData;
            deleteUrl.value = projectData.deleteUrl;
            breadHeaders.value = [{ label: ChangeWordList.ResidentOrPeople }];
            // 获取初始排序
            const initSortRes = getInitSortField(projectType.value === 'community' ? 'pmNewComResident' : 'pmOfficePeople');
            requestData.value.param.Sort = initSortRes.sort;
            requestData.value.param.SortField = initSortRes.sortField;
        }, {
            immediate: true
        });
        const { buildOptions, aptOptions, setRoomOption } = chooseBuildRoomInit();
        const update = ref(0);
        // 导入用户更新
        eventBus.on('refreshResident', () => {
            update.value += 1;
        });
        const saveParamInPath = ref(false);
        const searchList = () => {
            update.value += 1;
            saveParamInPath.value = true;
        };
        watch(() => requestData.value.param.Build, (value) => {
            requestData.value.param.Room = 'all';
            if (value === 'all') setRoomOption();
            else setRoomOption(value as string);
        });

        const userId = ref('');
        const parentId = ref('');
        const isShowPay = ref(false);
        const action: Array<ListActionItem> = [{
            type: 'pay',
            event(value: { ID: string; ParentID: string }) {
                if (community.ChargeMode.value === 1) {
                    Notice.messageBox(
                        'alert',
                        WordList.AdditionalAppPermissionsNotice,
                        WordList.PersonuserInfo,
                        'info'
                    )(() => true);
                    return;
                }
                userId.value = value.ID;
                parentId.value = value.ParentID;
                isShowPay.value = true;
            },
            class: 'modify-icon el-icon-my-pay',
            showCondition(val) {
                return val.Active === '0' && val.Role === '21';
            },
            title: WordList.OrderPay
        }, {
            type: 'view',
            event(value: { ID: string; ParentID: string }) {
                router.push(`/${propertyBaseRouter}/${propertySubRouter.residentInfo}?id=${value.ID}&type=${projectType.value}`);
            },
            class: 'modify-icon el-icon-my-eye',
            title: WordList.ProperAllTextView
        }, {
            type: 'delete2',
            event(value: { ID: string; Role: string }) {
                remove(WordList.DeleteAptTip, () => {
                    HttpRequest.post(deleteUrl.value, {
                        ID: value.ID
                    }, () => {
                        update.value += 1;
                    });
                });
            },
            class: 'el-icon-delete delete-icon',
            title: WordList.TabelConfigInHtmlDelete,
            showCondition() {
                return community.enableDeleteAccount.value;
            }
        }];

        const add = () => {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.residentSet}`);
        };

        // 获取排序方式, 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            requestData.value.param.Sort = sort;
            requestData.value.param.SortField = sortField;
            update.value += 1;
        }
        console.log(1);

        return {
            breadHeaders,
            searchKeyList,
            headers,
            buildOptions,
            aptOptions,
            requestData,
            filterEmptyCellValue,
            action,
            update,
            userId,
            parentId,
            isShowPay,
            add,
            saveParamInPath,
            searchList,
            projectType,
            isNew: community.isNew,
            enableDeleteAccount: community.enableDeleteAccount,
            deleteUrl,
            setAllImg,
            getSortImg,
            getSortRes
        };
    }
});
