import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cus_button = _resolveComponent("cus-button")
  const _component_import_resident_dialog = _resolveComponent("import-resident-dialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.projectType==='community')
      ? (_openBlock(), _createBlock(_component_cus_button, {
          key: 0,
          class: "margin-right10px",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowImportResidentDialog=true))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$wordList.ImportResident), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode("input", {
      type: "file",
      ref: "fileRef",
      accept: ".xlsx",
      style: {"display":"none"},
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.importResident && _ctx.importResident(...args)))
    }, null, 544),
    (_ctx.isShowImportResidentDialog)
      ? (_openBlock(), _createBlock(_component_import_resident_dialog, {
          key: 1,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isShowImportResidentDialog=false)),
          onImport: _ctx.setFile
        }, null, 8, ["onImport"]))
      : _createCommentVNode("", true)
  ], 64))
}