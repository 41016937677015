
import { defineComponent, ref } from 'vue';
import ImportFaceDialog from './components/import-face-dialog.vue';

export default defineComponent({
    components: {
        ImportFaceDialog
    },
    setup() {
        // 展示导入人脸选择文件弹窗
        const isShowImportDialog = ref(false);

        return {
            isShowImportDialog
        };
    }
});
